import { BaseFormFieldProps } from "./BaseFormFieldProps";
import { FieldValues, useController } from "react-hook-form";
import { DurationInput } from "../UI/DurationInput";

type Props<TFieldValues> = BaseFormFieldProps<
  TFieldValues,
  {
    label: string;
  }
>;

export function FormDurationInput<TFieldValues extends FieldValues>({
  fieldName,
  control,
  required,
  label,
}: Props<TFieldValues>) {
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control: control,
    rules: { required: required },
  });

  return <DurationInput label={label} value={value} onChange={onChange} />;
}
