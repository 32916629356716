import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  className: string;
}>;
export function Label({ className, children }: Props) {
  return (
    <label className={[className ?? "", "block"].join(" ")}>
      {children ?? null}
    </label>
  );
}
