import { FieldValues, useController } from "react-hook-form";
import { TextAreaWithLabel } from "../UI/TextAreaWithLabel";
import { BaseFormFieldProps } from "./BaseFormFieldProps";
type Props<TFieldValues> = BaseFormFieldProps<
  TFieldValues,
  {
    label: string;
  }
>;
export function FormTextAreaField<TFieldValues extends FieldValues>({
  fieldName,
  control,
  label,
  required,
}: Props<TFieldValues>) {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name: fieldName,
    control: control,
    rules: { required: required },
  });

  return (
    <TextAreaWithLabel
      errorString={invalid ? "Devi specificare un titolo" : undefined}
      label={label}
      value={value}
      onChange={onChange}
    />
  );
}
