import { JustMeetAddress } from "../Geocode/JustMeetAddress";
import { Fragment, useEffect, useRef, useState } from "react";
import { GooglePrediction } from "../Geocode/GooglePrediction";
import { nanoid } from "nanoid";
import { geocodeByPlaceId } from "../Geocode/geocodeByPlaceId";
import { placesAutocomplete } from "../Geocode/placesAutocomplete";
import { Label } from "./Label";
import { Combobox, Transition } from "@headlessui/react";
import { PrimaryLoadingIndicator } from "./PrimaryLoadingIndicator";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { SimpleErrorMessage } from "./SimpleErrorMessage";

export function PlacesAutocompleteInput({
  label,
  debounce = 500,
  onChange,
  value,
  errorString,
}: {
  label: string;
  debounce?: number;
  onChange?: (v: JustMeetAddress) => void;
  value?: JustMeetAddress;
  errorString?: string;
}) {
  const [selected, setSelected] = useState();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<GooglePrediction[]>([]);
  const searchTimeout = useRef<NodeJS.Timeout>();
  const [sessionToken, setSessionToken] = useState(nanoid());

  async function selectPrediction(place: GooglePrediction) {
    setSessionToken(nanoid());
    setSuggestions([]);
    const address = await geocodeByPlaceId(place.placeId, sessionToken);
    onChange?.(address);
  }

  useEffect(() => {
    if (searchTimeout.current) clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(async () => {
      if (query.length <= 0) return;
      try {
        setLoading(true);
        const res = await placesAutocomplete(query, sessionToken);
        setSuggestions(res);
      } finally {
        setLoading(false);
      }
    }, debounce);
  }, [query, debounce, sessionToken]);

  function formatValue(): string | undefined {
    if (!value) return;
    return JustMeetAddress.fromDTO(value).toString();
  }

  return (
    <div className="">
      <Label className={"mb-2"}>{label}</Label>
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              placeholder={formatValue()}
              className="w-full py-2 pl-3 pr-10 rounded-lg text-sm leading-5 text-gray-900 focus:ring-0 border border-slate-200"
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              {loading ? (
                <PrimaryLoadingIndicator size={"small"} />
              ) : (
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {suggestions.length <= 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                suggestions.map((place) => (
                  <Combobox.Option
                    key={place.placeId}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-purple-600 text-white" : "text-gray-900"
                      }`
                    }
                    onClick={async () => {
                      await selectPrediction(place);
                    }}
                    value={place}
                  >
                    {() => (
                      <>
                        <span>{place.description}</span>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      <div className={"flex justify-center"}>
        <SimpleErrorMessage className={"mt-2"} error={errorString} />
      </div>
    </div>
  );
}
