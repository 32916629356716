import { useFirebaseUser } from "./useFirebaseUser";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
export function TokenScreen() {
  const { user, loading } = useFirebaseUser();
  const [token, setToken] = useState("");
  useEffect(() => {
    (async function setup() {
      if (!user) return;
      const token = await user.getIdToken();
      setToken(token);
    })();
  }, [user]);
  if (loading) return null;
  if (!user) return <Navigate to={"/login"} />;

  return <div>{token}</div>;
}
