type Props = {
  size: "small" | "large";
};
export function WhiteLoadingIndicator(props: Props) {
  return (
    <div
      className={[
        "border-t-white border-2 border-white/20 animate-spin rounded-full",
        props.size === "large" ? "w-12 h-12" : "w-4 h-4",
      ].join(" ")}
    />
  );
}
