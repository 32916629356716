import { gql, useMutation } from "@apollo/client";

const LOGICALLY_DELETE_EVENT_MUTATION = gql`
  mutation backofficeLogicallyDeleteEvent($eventId: String!) {
    backofficeLogicallyDeleteEvent(eventId: $eventId)
  }
`;

type Response = {
  backofficeLogicallyDeleteEvent: boolean;
};

type Input = {
  eventId: string;
};

export function useLogicallyDeleteEvent() {
  const [logicallyDeleteEventMutation, { loading }] = useMutation<
    Response,
    Input
  >(LOGICALLY_DELETE_EVENT_MUTATION);

  async function logicallyDeleteEvent(eventId: string) {
    await logicallyDeleteEventMutation({
      variables: {
        eventId,
      },
      update(cache) {
        cache.evict({ id: `Event:${eventId}` });
      },
    });
  }

  return {
    logicallyDeleteEvent,
    loading,
  };
}
