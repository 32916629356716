import { gql, useMutation } from "@apollo/client";

const SEND_GO_TO_EVENT_NOTIFICATION = gql`
  mutation sendGoToEventNotificationToAllUsers(
    $eventId: String!
    $message: String!
    $lang: String!
  ) {
    sendGoToEventNotificationToAllUsers(
      message: $message
      lang: $lang
      eventId: $eventId
    ) {
      usersNotifiedCount
    }
  }
`;

type Response = {
  sendGoToEventNotificationToAllUsers: {
    usersNotifiedCount: number;
  };
};
type Input = {
  eventId: string;
  message: string;
  lang: string;
};
export function useSendGoToEventNotificationToAllUsers() {
  const [sendGoToEventNotificationToAllUsersMutation, { loading }] =
    useMutation<Response, Input>(SEND_GO_TO_EVENT_NOTIFICATION);

  async function sendGoToEventNotificationToAllUsers(
    eventId: string,
    message: string,
    lang: string
  ): Promise<{
    usersNotifiedCount: number;
  }> {
    const { data } = await sendGoToEventNotificationToAllUsersMutation({
      variables: { eventId, message, lang },
    });
    return {
      usersNotifiedCount:
        data?.sendGoToEventNotificationToAllUsers?.usersNotifiedCount ?? 0,
    };
  }

  return {
    sendGoToEventNotificationToAllUsers,
    loading,
  };
}
