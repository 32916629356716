import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getFirebaseAuth } from "../Firebase/getFirebaseAuth";
import { useEffect, useRef, useState } from "react";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";

import { TextInput } from "../UI/TextInput";
import { ConfirmationResult } from "@firebase/auth";
import { Layout } from "../Layout/Layout";

export function LoginScreen() {
  const auth = getFirebaseAuth();
  const recaptchaContainer = useRef<HTMLDivElement>(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();
  const [signingIn, setSigningIn] = useState(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const [code, setCode] = useState("");
  const [showPhoneError, setShowPhoneError] = useState(false);

  async function signIn() {
    if (!recaptchaVerifier) throw new Error("No recaptcha verifier");

    try {
      setSigningIn(true);
      setConfirmationResult(
        await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
      );
    } finally {
      setSigningIn(false);
    }
  }

  useEffect(() => {
    setRecaptchaVerifier(
      new RecaptchaVerifier(
        recaptchaContainer.current as HTMLElement,
        {
          size: "invisible",
        },
        auth
      )
    );
  }, [auth]);

  async function verifyCode() {
    if (!confirmationResult) throw new Error("No confirmation result");

    try {
      setSigningIn(true);
      await confirmationResult.confirm(code);
      window.location.href = "/";
    } finally {
      setSigningIn(false);
    }
  }

  async function logIn() {
    if (!phoneNumber) return setShowPhoneError(true);
    if (!phoneNumber.startsWith("+")) return setShowPhoneError(true);
    await signIn();
  }

  return (
    <Layout>
      <div className={"flex justify-center flex-1 items-center flex-col"}>
        <div ref={recaptchaContainer} />
        {confirmationResult ? (
          <>
            <h1 className={"font-bold text-center text-lg mb-2"}>
              Inserisci codice
            </h1>
            <div className={"mb-4 max-w-[400px]"}>
              <TextInput onChange={(v) => setCode(v)} value={code} />
            </div>
            <PrimarySolidButton
              loading={signingIn}
              onClick={async () => {
                if (code.length < 6) return;
                await verifyCode();
              }}
              label={"Accedi"}
            />
          </>
        ) : (
          <>
            <h1 className={"font-bold text-center text-lg mb-2"}>Accedi</h1>
            <div className={"mb-4"}>
              <TextInput
                placeholder={"+39XXXXXXXXXX"}
                errorString={
                  showPhoneError
                    ? "Inserisci un numero di telefono valido che comprenda il prefisso della tua nazione. es: +39XXXXXXXXXX"
                    : undefined
                }
                onEnterPressed={logIn}
                onChange={(v) => {
                  setShowPhoneError(false);
                  setPhoneNumber(v);
                }}
                value={phoneNumber}
              />
            </div>
            <PrimarySolidButton
              loading={signingIn}
              onClick={logIn}
              label={"Invia codice"}
            />
          </>
        )}
      </div>
    </Layout>
  );
}
