import { Layout } from "../../Layout/Layout";
import { useMyEvents } from "./useMyEvents";
import { EventCard } from "./EventCard";
import { PrimarySolidButton } from "../../UI/PrimarySolidButton";
import { useState } from "react";
import { LineDivider, LineDividerSpacing } from "../../UI/LineDivider";
import { Select, SelectValue } from "../../UI/Select";
import { FilterEventByOpennessType } from "./FilterEventByOpenessType";
import { Label } from "../../UI/Label";
import { usePossibleEventTypes } from "../CreateEvent/usePossibleEventTypes";

export function MyEventListScreen() {
  const { loading: loadingEventTypes, types } = usePossibleEventTypes();
  const filterEventByOpennessTypes: SelectValue[] = [
    {
      id: FilterEventByOpennessType.All,
      name: "Tutti",
    },
    {
      id: FilterEventByOpennessType.Public,
      name: "Pubblico",
    },
    {
      id: FilterEventByOpennessType.Private,
      name: "Privato",
    },
  ];
  const [filterByOpennessType, setFilterByOpennessType] = useState<SelectValue>(
    filterEventByOpennessTypes[0]
  );
  const { events, fetchNextPage, hasMore, fetchingMore } = useMyEvents({
    pageSize: 30,
    afterDate: new Date(),
    excludeCancelled: true,
    filterByOpennessType: filterByOpennessType?.id as FilterEventByOpennessType,
  });

  return (
    <Layout loading={loadingEventTypes} showMenu>
      <div className={"p-4"}>
        <div className={"mb-4"}>
          <Label className={"mb-2"}>Filtra per pubblico/privato</Label>
          <Select
            selected={filterByOpennessType}
            setSelected={setFilterByOpennessType}
            options={filterEventByOpennessTypes}
          />
        </div>
        <LineDivider spacing={LineDividerSpacing.ExtraLarge} />
        <div className={"flex flex-col space-y-4"}>
          {events.map((ev) => (
            <EventCard possibleEventTypes={types} event={ev} key={ev.id} />
          ))}
          {hasMore ? (
            <div className={"flex justify-center"}>
              <PrimarySolidButton
                loading={fetchingMore}
                onClick={fetchNextPage}
                label={"Carica di più"}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}
