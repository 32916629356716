import { FieldValues, useController } from "react-hook-form";
import dayjs from "dayjs";
import { Label } from "../UI/Label";
import { formatForInputDateTime } from "../DateUtils/formatForInputDateTime";
import { BaseFormFieldProps } from "./BaseFormFieldProps";

type Props<TFieldValues> = BaseFormFieldProps<
  TFieldValues,
  {
    label: string;
  }
>;
export function FormDateTimeField<TFieldValues extends FieldValues>({
  fieldName,
  control,
  required,
  label,
}: Props<TFieldValues>) {
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control: control,
    rules: { required: required },
  });

  return (
    <div>
      <Label className={"mb-2"}>{label}</Label>
      <input
        className={"border rounded-md h-10 md:min-w-[300px] p-2 w-full"}
        type="datetime-local"
        id="meeting-time"
        name="meeting-time"
        value={formatForInputDateTime(dayjs(value))}
        onChange={onChange}
        min={formatForInputDateTime(dayjs())}
      />
    </div>
  );
}
