import { gql, useMutation } from "@apollo/client";

const SEND_NOTIFICATION_TO_ALL_USERS = gql`
  mutation sendNotificationToAllUsers($message: String!, $lang: String!) {
    sendNotificationToAllUsers(message: $message, lang: $lang) {
      usersNotifiedCount
    }
  }
`;

type Response = {
  sendNotificationToAllUsers: {
    usersNotifiedCount: number;
  };
};

type Input = {
  message: string;
  lang: string;
};
export function useSendNotificationToAllUsers() {
  const [sendNotificationToAllUsersMutation, { loading }] = useMutation<
    Response,
    Input
  >(SEND_NOTIFICATION_TO_ALL_USERS);

  async function sendNotificationToAllUsers(
    message: string,
    lang: string
  ): Promise<{
    usersNotifiedCount: number;
  }> {
    const { data } = await sendNotificationToAllUsersMutation({
      variables: { message, lang },
    });
    return {
      usersNotifiedCount:
        data?.sendNotificationToAllUsers?.usersNotifiedCount ?? 0,
    };
  }
  return {
    sendNotificationToAllUsers,
    loading,
  };
}
