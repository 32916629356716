import { ComposableStringBuilder } from "../StringUtils/ComposableStringBuilder";
export type JustMeetAddressDTO = {
  street: string;
  city: string;
  province: string;
  shortProvince: string;
  cap: string;
  region: string;
  country: string;
  streetNumber: string;
  lat: number;
  lng: number;
  placeId: string;
};
export class JustMeetAddress {
  constructor(
    public readonly street: string,
    public readonly streetNumber: string,
    public readonly region: string,
    public readonly city: string,
    public readonly province: string,
    public readonly shortProvince: string,
    public readonly cap: string,
    public readonly country: string,
    public readonly placeId: string,
    public readonly lat: number,
    public readonly lng: number
  ) {}

  public toString(): string {
    return new ComposableStringBuilder()
      .appendNotNull(this.street)
      .appendNotNull(this.streetNumber)
      .appendNotNull(this.city)
      .appendNotNull(this.province)
      .appendNotNull(this.cap)
      .appendNotNull(this.country)
      .build();
  }

  public static fromDTO(dto?: JustMeetAddressDTO): JustMeetAddress {
    return new JustMeetAddress(
      dto?.street ?? "",
      dto?.streetNumber ?? "",
      dto?.region ?? "",
      dto?.city ?? "",
      dto?.province ?? "",
      dto?.shortProvince ?? "",
      dto?.cap ?? "",
      dto?.country ?? "",
      dto?.placeId ?? "",
      dto?.lat ?? 0,
      dto?.lng ?? 0
    );
  }

  public isComplete(): boolean {
    const interestingParts: string[] = [
      this.region,
      this.cap,
      this.city,
      this.country,
      this.province,
      this.shortProvince,
    ];

    return (
      interestingParts.every((part) => part.length > 0) &&
      this.lat !== 0 &&
      this.lng !== 0
    );
  }
}
