import { ComboBox, ComboBoxValue } from "../UI/ComboBox";
import { useEffect, useRef, useState } from "react";
import { useSearchEvents } from "./useSearchEvents";
type Props = {
  selected?: ComboBoxValue;
  setSelected: (value: ComboBoxValue) => void;
  errorString?: string;
};
export function SearchEvent({ selected, setSelected, errorString }: Props) {
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState(query);
  const [debouncing, setDebouncing] = useState(false);

  const debounceTimeout = useRef<NodeJS.Timeout>();

  const { events, loading } = useSearchEvents(searchQuery);

  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      setSearchQuery(query);
      debounceTimeout.current = undefined;
      setDebouncing(false);
    }, 300);
  }, [query]);

  return (
    <ComboBox
      placeholder={"Cerca eventi"}
      errorString={errorString}
      loading={loading || debouncing}
      items={events.map((e) => ({
        name: e.title,
        id: e.id,
        imageUrl: e.gallery?.length ? e.gallery[0].src : undefined,
      }))}
      query={query}
      setQuery={(q) => {
        setQuery(q);
        if (!debouncing && q !== "") setDebouncing(true);
        if (q === "") setDebouncing(false);
      }}
      setSelected={setSelected}
      selected={selected}
    />
  );
}
