import { TextInput } from "./TextInput";
import { useEffect, useState } from "react";
import _ from "lodash";

type Props = {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
};
export function DebouncedTextInput({ value, onChange, placeholder }: Props) {
  const [internalValue, setInternalValue] = useState("");
  const [{ debouncedFn }] = useState({
    debouncedFn: _.debounce((currentVal: string) => {
      onChange(currentVal);
    }, 500),
  });

  useEffect(() => {
    setInternalValue(value ?? "");
  }, [value]);

  return (
    <TextInput
      placeholder={placeholder}
      value={internalValue}
      onChange={(newV) => {
        setInternalValue(newV);
        debouncedFn(newV);
      }}
    />
  );
}
