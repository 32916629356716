import { Layout } from "../../Layout/Layout";
import { useParams } from "react-router-dom";
import { useEventById } from "../useEventById";
import { FullScreenLoading } from "../../UI/FullScreenLoading";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { SelectValue } from "../../UI/Select";
import { Duration } from "../../UI/DurationInput";
import { Range } from "../../Range";
import { JustMeetAddressDTO } from "../../Geocode/JustMeetAddress";
import { ImageSrcAndFileBag } from "../../UI/ImageInput";
import { EventType } from "../EventType";
import { FormSelectField } from "../../Form/FormSelectField";
import { usePossibleEventTypes } from "../CreateEvent/usePossibleEventTypes";
import { useEffect, useState } from "react";
import { FormTextField } from "../../Form/FormTextField";
import { FormTextAreaField } from "../../Form/FormTextAreaField";
import { FormGalleryInput } from "../../Form/FormGalleryInput";
import { FormDateTimeField } from "../../Form/FormDateTimeField";
import { nanoid } from "nanoid";
import { useUpdateEvent } from "./useUpdateEvent";
import { PrimarySolidButton } from "../../UI/PrimarySolidButton";
import { Warning } from "../../UI/Warning";
import { usePublishEvent } from "./usePublishEvent";
import { ErrorNotificationToast } from "../../UI/ErrorNotificationToast";
import { useErrorBag } from "../../UI/useErrorBag";
import { DangerButton } from "../../UI/DangerButton";
import { useLogicallyDeleteEvent } from "../useLogicallyDeleteEvent";

type FormFields = {
  title: string;
  description: string;
  date: string;
  eventType: SelectValue;
  duration: Duration;
  minMaxAge: Range;
  minMaxPeople: Range;
  place: JustMeetAddressDTO;
  gallery: ImageSrcAndFileBag[];
};

function srcToFile(
  src: string,
  fileName: string,
  mimeType: string
): Promise<File> {
  return fetch(src)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}

function urlToMimeType(url: string): string {
  const extension = url.substring(url.lastIndexOf("."));
  switch (extension) {
    case ".jpeg":
    case ".jpg":
      return "image/jpg";
    case ".png":
      return "image/png";
    default:
      throw new Error(`Unknown file extension`);
  }
}
export function EditEventScreen() {
  const { eventId } = useParams<{
    eventId: string;
  }>();
  const { loading: updatingEvent, updateEvent } = useUpdateEvent();
  const { event, loading } = useEventById(eventId);
  const { loading: loadingEventTypes, types } = usePossibleEventTypes();
  const [initialDataLoading, setInitialDataLoading] = useState(true);
  const { loading: publishingEvent, publishEvent } = usePublishEvent();
  const { errorBag, openError, closeError } = useErrorBag();
  const { logicallyDeleteEvent, loading: deleting } = useLogicallyDeleteEvent();
  const { handleSubmit, control, setValue, formState, reset } =
    useForm<FormFields>({
      defaultValues: {
        date: dayjs().add(1, "day").startOf("date").hour(12).toISOString(),
        title: "",
        description: "",
        minMaxAge: {
          min: 18,
          max: 50,
        },
        minMaxPeople: {
          min: 0,
          max: 200,
        },
        gallery: [],
      },
    });

  async function onSubmit(v: FormFields) {
    await updateEvent({
      title: v.title,
      description: v.description,
      eventType: v.eventType.id as EventType,
      gallery: v.gallery.map((g) => g.file),
      eventId: eventId as string,
      date: dayjs(v.date).toDate(),
    });
    reset(undefined, {
      keepValues: true,
    });
  }

  async function askDeleteConfirmation() {
    const confirmed = window.confirm(
      "Sei sicuro di voler eliminare questo evento? L'operazione non può essere annullata"
    );
    if (!confirmed) return;
    await logicallyDeleteEvent(eventId as string);
    window.location.href = "/my-events";
  }

  async function publish() {
    console.log(`Event type:`, event?.type);
    if (event?.type === EventType.UNKNOWN) {
      return openError(
        "Errore",
        "Devi prima specificare una tipologia di evento"
      );
    }
    await publishEvent(event?.id as string);
  }

  useEffect(() => {
    (async function init() {
      try {
        if (!event) return;
        const type =
          event.type === EventType.UNKNOWN
            ? {
                name: "Tipologia evento sconosciuta",
                id: event.type,
              }
            : types.find((t) => t.type === event.type);
        if (!type) return;
        const galleryFiles = await Promise.all(
          event.gallery.map((g) =>
            srcToFile(g.src, nanoid(), urlToMimeType(g.src))
          )
        );
        setValue("eventType", {
          id: event.type,
          name: type.name,
        });
        setValue("title", event.title);
        setValue("description", event.description);
        setValue("date", dayjs(event.date).toISOString());
        setValue(
          "gallery",
          event.gallery.map((g, index) => ({
            src: g.src,
            file: galleryFiles[index],
          }))
        );
      } finally {
        setInitialDataLoading(false);
      }
    })();
  }, [event, types, setValue]);

  if (loading || loadingEventTypes) return <FullScreenLoading />;
  if (!event)
    return (
      <Layout showMenu>
        <div className={"p-4"}>
          <Warning
            description={`Impossibile recuperare l'evento con ID: ${eventId}`}
            title={"Errore"}
          />
        </div>
      </Layout>
    );
  if (initialDataLoading) return <FullScreenLoading />;
  if (event.type === EventType.VIRTUAL_EVENT)
    return (
      <Layout showMenu>
        <div className={"p-4"}>
          <Warning
            description={"Gli eventi virtuali non sono ancora supportati"}
            title={"Tipologia evento non supportata"}
          />
        </div>
      </Layout>
    );
  return (
    <Layout showMenu>
      <ErrorNotificationToast
        durationInMs={2000}
        title={errorBag.title}
        description={errorBag.description}
        close={closeError}
        show={errorBag.show}
      />
      <div className={"p-4"}>
        <div className={"grid gap-6"}>
          <div>
            <FormSelectField
              msgInCaseOfError={"Devi selezionare un tipo di evento"}
              required
              placeholder={"Seleziona un tipo di evento"}
              control={control}
              fieldName={"eventType"}
              label={"Tipologia evento"}
              options={types
                .filter((t) => t.type !== EventType.VIRTUAL_EVENT)
                .map((t) => ({
                  name: t.name,
                  id: t.type,
                }))}
            />
          </div>
          <div>
            <FormTextField
              required
              msgInCaseOfError={"Devi specificare un titolo"}
              label={"Titolo"}
              fieldName={"title"}
              control={control}
            />
          </div>
          <div>
            <FormTextAreaField
              label={"Descrizione"}
              fieldName={"description"}
              control={control}
            />
          </div>
          <div className={"mr-auto"}>
            <FormGalleryInput
              validate={(v) => {
                return !!v && (v as ImageSrcAndFileBag[]).length > 0;
              }}
              msgInCaseOfError={"Devi inserire almeno un'immagine"}
              required
              label={"Immagini"}
              control={control}
              fieldName={"gallery"}
            />
          </div>
          <div>
            <FormDateTimeField
              fieldName={"date"}
              control={control}
              label={"Data"}
            />
          </div>
        </div>
        <div className={"flex justify-center mt-8 items-center space-x-2"}>
          <PrimarySolidButton
            loading={updatingEvent}
            onClick={() => {
              handleSubmit(onSubmit)().catch((e) => {
                console.error("e", e);
              });
            }}
            disabled={!formState.isDirty}
            label={"Aggiorna evento"}
          />
          {event.isPrivate ? (
            <PrimarySolidButton
              loading={publishingEvent}
              disabled={formState.isDirty}
              onClick={publish}
              label={"Pubblica evento"}
            />
          ) : null}
          {event.isPrivate ? (
            <DangerButton
              onClick={askDeleteConfirmation}
              disabled={formState.isDirty}
              loading={deleting}
              label={"Elimina evento"}
            />
          ) : null}
        </div>
      </div>
    </Layout>
  );
}
