import { getFirebaseAuth } from "./getFirebaseAuth";

export async function getIdTokenAsync() {
  return new Promise<string | undefined>((resolve) => {
    const unsubscribe = getFirebaseAuth().onAuthStateChanged(async (user) => {
      unsubscribe();
      if (user) resolve(await user.getIdToken());
      else resolve(undefined);
    });
  });
}
