import { EventType } from "./EventType";

export function formatEventType(type: EventType): string {
  switch (type) {
    case EventType.LUNCH:
      return "Pranzo";
    case EventType.DINNER:
      return "Cena";
    case EventType.STUDY:
      return "Studio";
    case EventType.BREAKFAST:
      return "Colazione";
    case EventType.BUSINESS:
      return "Lavoro";
    case EventType.CARRALLIES:
      return "Car rallies";
    case EventType.PHOTOGRAPHY:
      return "Fotografia";
    case EventType.VIRTUAL_EVENT:
      return "Event virtuale";
    case EventType.PARTY:
      return "Party";
    case EventType.FILM:
      return "Film";
    case EventType.SPORT:
      return "Sport";
    case EventType.PHILOSOPHY:
      return "Salotto filosofico";
    case EventType.GAMES:
      return "Giochi";
    case EventType.JAM_SESSION:
      return "Jam session";
    case EventType.FESTIVAL:
      return "Festival";
    case EventType.DISCO:
      return "Discoteca";
    case EventType.EXCURSION:
      return "Escursioni";
    case EventType.ANIMALS:
      return "Animali";
    case EventType.APERITIF:
      return "Aperitivo";
    case EventType.ART_WORKSHOP:
      return "Laboratorio artistico";
    default:
      return "Tipologia evento sconosciuta";
  }
}
