import ReactDOM from "react-dom";
import { useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
type Props = {
  durationInMs: number;
  title: string;
  description: string;
  close: () => void;
  show: boolean;
};
export function SuccessNotificationToast({
  durationInMs,
  close,
  show,
  ...props
}: Props) {
  const currentTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!show) return;
    if (currentTimeoutRef.current) clearTimeout(currentTimeoutRef.current);

    currentTimeoutRef.current = setTimeout(() => {
      close();
      currentTimeoutRef.current = undefined;
    }, durationInMs);
  }, [durationInMs, close, show]);
  return ReactDOM.createPortal(
    <Transition
      show={show}
      enter="transition-opacity duration-600"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-600"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={
          "fixed mx-auto z-10 left-0 top-0 p-4 right-0 flex justify-center"
        }
      >
        <div
          className={
            "bg-teal-500 shadow-xl shadow-slate-300 max-w-[400px] p-4 rounded-lg text-white flex items-center"
          }
        >
          <CheckCircleIcon className={"mr-2 h-6 w-6 flex-shrink-0"} />
          <div className={"text-white"}>
            <h1 className={"font-bold"}>{props.title}</h1>
            <p className={"opacity-90"}>{props.description}</p>
          </div>
        </div>
      </div>
    </Transition>,
    document.body
  );
}
