import { gql, useMutation } from "@apollo/client";
import { EventType } from "../EventType";

const UPDATE_EVENT_MUTATION = gql`
  mutation updateEvent(
    $eventId: String!
    $title: String
    $description: String
    $gallery: [Upload]
    $eventType: EventType
    $date: DateTime
  ) {
    updateEvent(
      eventId: $eventId
      eventType: $eventType
      title: $title
      description: $description
      gallery: $gallery
      date: $date
    ) {
      ... on Event {
        id
        type
        date
        title
        description
        gallery {
          src
        }
        isPrivate
        addressString
        endAt
      }
    }
  }
`;

type UpdateEventInput = {
  eventId: string;
  eventType?: EventType;
  date?: Date;
  gallery?: File[];
  title?: string;
  description?: string;
};

type UpdateEventResponse = {
  updateEvent: {
    id: string;
  };
};
export function useUpdateEvent() {
  const [updateEventMutation, { loading }] = useMutation<
    UpdateEventResponse,
    UpdateEventInput
  >(UPDATE_EVENT_MUTATION);
  async function updateEvent(update: UpdateEventInput): Promise<void> {
    await updateEventMutation({
      variables: update,
    });
  }

  return {
    updateEvent,
    loading,
  };
}
