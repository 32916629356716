import { Menu } from "../UI/Menu";
import { MenuLink } from "../UI/MenuLink";
import { useMenuCtx } from "./useMenuCtx";
import {
  BellIcon,
  PlusCircleIcon,
  PencilSquareIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
const notificationPathName = "/notifications";
const createEventPathName = "/create-event";
const myEventsPathName = "/my-events";
const allEventsPathName = "/all-events";
export function JustMeetMenu() {
  const { closeMenu, openedOnMobile } = useMenuCtx();
  const location = useLocation();
  return (
    <Menu openedOnMobile={openedOnMobile} onClose={closeMenu}>
      <MenuLink
        onNavigate={closeMenu}
        icon={<BellIcon className={"h-5 w-5"} />}
        isActive={location.pathname.includes(notificationPathName)}
        href={notificationPathName}
        label={"Notifiche"}
      />
      <MenuLink
        onNavigate={closeMenu}
        icon={<PlusCircleIcon className={"h-5 w-5"} />}
        isActive={location.pathname.includes(createEventPathName)}
        href={createEventPathName}
        label={"Crea evento"}
      />
      <MenuLink
        onNavigate={closeMenu}
        icon={<PencilSquareIcon className={"h-5 w-5"} />}
        isActive={location.pathname.includes(myEventsPathName)}
        href={myEventsPathName}
        label={"Modifica evento"}
      />
      <MenuLink
        onNavigate={closeMenu}
        icon={<WrenchScrewdriverIcon className={"h-5 w-5"} />}
        isActive={location.pathname.includes(allEventsPathName)}
        href={allEventsPathName}
        label={"Gestisci eventi"}
      />
    </Menu>
  );
}
