import { EventDTO } from "../EventDTO";
import dayjs from "dayjs";
import { formatEventType } from "../formatEventType";
import { useNavigate } from "react-router-dom";
import { useLogicallyDeleteEvent } from "../useLogicallyDeleteEvent";
import { DangerButton } from "../../UI/DangerButton";
import { Select } from "../../UI/Select";
import { PossibleEventType } from "../CreateEvent/usePossibleEventTypes";
import { EventType } from "../EventType";
import { useUpdateEvent } from "./useUpdateEvent";
import { PrimarySolidButton } from "../../UI/PrimarySolidButton";
import { usePublishEvent } from "./usePublishEvent";

type Props = {
  event: EventDTO;
  possibleEventTypes: PossibleEventType[];
};
export function EventCard({ event, possibleEventTypes }: Props) {
  const navigate = useNavigate();
  const dateFmtString = "ddd DD MMM YYYY [alle] HH:mm";

  const { logicallyDeleteEvent, loading: deleting } = useLogicallyDeleteEvent();
  const { publishEvent, loading: publishing } = usePublishEvent();
  const { updateEvent, loading: updatingEvent } = useUpdateEvent();

  async function updateEventType(type: EventType) {
    await updateEvent({
      eventId: event.id,
      eventType: type,
    });
  }

  function getDateInfoString(): string {
    const parts: string[] = [dayjs(event.date).format(dateFmtString)];
    if (!!event.endAt && !dayjs(event.date).isSame(event.endAt))
      parts.push(dayjs(event.endAt).format(dateFmtString));
    return parts.join(" - ");
  }
  async function askDeleteEventConfirmation() {
    const confirmed = window.confirm(
      "Sei sicuro di voler eliminare l'evento? L'operazione non può essere annullata"
    );
    if (!confirmed) return;
    await logicallyDeleteEvent(event.id);
  }
  const selectedType = possibleEventTypes.find((v) => v.type === event.type);
  return (
    <div
      onClick={() => {
        navigate(`/edit-event/${event.id}`);
      }}
      className={
        "p-3 border border-slate-200 shadow-lg shadow-slate-300/60 rounded-lg cursor-pointer"
      }
    >
      <div className={"flex justify-between items-start"}>
        <div className={"flex space-x-3 flex-1"}>
          <div
            className={
              "w-20 h-20 bg-no-repeat bg-center bg-cover rounded-lg flex-shrink-0"
            }
            style={{
              backgroundImage: `url("${event.gallery[0].src}")`,
            }}
          />
          <div>
            <h3 className={"font-bold text-gray-900"}>{event.title}</h3>
            <p className={"text-gray-600 text-sm"}>
              {formatEventType(event.type)}
            </p>
            <p className={"text-gray-600 text-sm"}>{event.addressString}</p>
            <p className={"text-gray-600 text-sm"}>{getDateInfoString()}</p>
            <p className={"text-gray-600 mt-2"}>{event.description}</p>
          </div>
        </div>
        {event.isPrivate ? (
          <p
            className={
              "bg-purple-300 text-xs px-2 py-1 text-purple-900 mt-1 rounded-md border border-purple-500 mr-auto"
            }
          >
            Privato
          </p>
        ) : (
          <p
            className={
              "bg-green-300 text-xs px-2 py-1 text-green-900 mt-1 rounded-md border border-green-500 mr-auto"
            }
          >
            Pubblico
          </p>
        )}
      </div>
      {event.isPrivate ? (
        <div className={"flex justify-end mt-4"}>
          <div className={"flex items-center space-x-4"}>
            <div className={"flex flex-col items-stretch min-w-[200px]"}>
              <Select
                noDefaultMargin
                loading={updatingEvent}
                selected={
                  selectedType
                    ? {
                        id: selectedType.type,
                        name: selectedType.name,
                      }
                    : undefined
                }
                setSelected={async (o) => {
                  await updateEventType(o.id as EventType);
                }}
                options={possibleEventTypes
                  .filter((t) => t.type !== EventType.VIRTUAL_EVENT)
                  .map((t) => ({
                    name: t.name,
                    id: t.type,
                  }))}
              />
            </div>
            <PrimarySolidButton
              loading={publishing}
              onClick={async (e) => {
                e.stopPropagation();
                await publishEvent(event.id);
              }}
              label={"Pubblica"}
              disabled={event.type === EventType.UNKNOWN}
            />
            <DangerButton
              label={"Elimina"}
              loading={deleting}
              onClick={askDeleteEventConfirmation}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
