import { gql, useMutation } from "@apollo/client";
import { EventDTO } from "../EventDTO";

const UN_SET_EVENT_AS_PINNED = gql`
  mutation unsetEventAsPinned($eventId: String!) {
    unsetEventAsPinned(eventId: $eventId) {
      ... on Event {
        id
        isStarred
      }
      ... on VirtualEvent {
        id
        isStarred
      }
    }
  }
`;

type Input = {
  eventId: string;
};

type Response = {
  unsetEventAsPinned: EventDTO;
};
export function useUnsetEventAsPinned() {
  const [unSetEvAsPinnedMutation, { loading }] = useMutation<Response, Input>(
    UN_SET_EVENT_AS_PINNED
  );

  async function unSetEventAsPinned(eventId: string) {
    await unSetEvAsPinnedMutation({
      variables: {
        eventId,
      },
    });
  }

  return {
    unSetEventAsPinned,
    loading,
  };
}
