import { FieldValues, useController } from "react-hook-form";
import { BaseFormFieldProps } from "./BaseFormFieldProps";
import { RangeInput } from "../UI/RangeInput";
type Props<TFieldValues> = BaseFormFieldProps<
  TFieldValues,
  {
    label: string;
    hideMin?: boolean;
  }
>;
export function FormRangeField<TFieldValues extends FieldValues>({
  control,
  fieldName,
  label,
  required,
  hideMin,
}: Props<TFieldValues>) {
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control: control,
    rules: { required: required },
  });
  return (
    <RangeInput
      hideMin={hideMin}
      onChange={onChange}
      value={value}
      label={label}
    />
  );
}
