import { classNames } from "./CssUtils/classNames";
export enum LineDividerSpacing {
  None = "none",
  Small = "small",
  Medium = "medium",
  Large = "large",
  ExtraLarge = "extraLarge",
}
type Props = {
  spacing?: LineDividerSpacing;
};
export function LineDivider({ spacing }: Props) {
  function getSpacingClass(): string {
    switch (spacing) {
      case LineDividerSpacing.Small:
        return "my-1";
      case LineDividerSpacing.Medium:
        return "my-3";
      case LineDividerSpacing.Large:
        return "my-4";
      case LineDividerSpacing.ExtraLarge:
        return "my-6";
      default:
        return "my-0";
    }
  }

  return (
    <div
      className={classNames(["bg-slate-100 w-full h-px", getSpacingClass()])}
    />
  );
}
