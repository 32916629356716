import { useAllFutureEvents } from "./useAllFutureEvents";
import { EventsTable } from "./EventsTable";
import { Layout } from "../../Layout/Layout";

export function AllEventsScreen() {
  const { events, loading } = useAllFutureEvents();

  return (
    <Layout loading={loading} showMenu>
      <div className={"p-4"}>
        <EventsTable events={events} />
      </div>
    </Layout>
  );
}
