import { gql, useMutation } from "@apollo/client";

const PUBLISH_EVENT_MUTATION = gql`
  mutation publishEvent($eventId: String!) {
    publishEvent(eventId: $eventId) {
      ... on Event {
        id
        isPrivate
      }
      ... on VirtualEvent {
        id
        isPrivate
      }
    }
  }
`;

type PublishEventResponse = {
  publishEvent: boolean;
};

type PublishEventInput = {
  eventId: string;
};
export function usePublishEvent() {
  const [publishEventMutation, { loading }] = useMutation<
    PublishEventResponse,
    PublishEventInput
  >(PUBLISH_EVENT_MUTATION);

  async function publishEvent(eventId: string) {
    await publishEventMutation({
      variables: {
        eventId,
      },
    });
  }
  return {
    loading,
    publishEvent,
  };
}
