import { CardInputWithLabel } from "./CardInputWithLabel";
import { useErrorBag } from "./useErrorBag";
import { ErrorNotificationToast } from "./ErrorNotificationToast";
import { Label } from "./Label";
import { Range } from "../Range";

type Props = {
  value?: Range;
  onChange: (v: Range) => void;
  label: string;
  hideMin?: boolean;
};
export function RangeInput({ value, onChange, label, hideMin }: Props) {
  const v = value ?? new Range(0, 0);
  const { errorBag, openError, closeError } = useErrorBag();
  function validate(min: number, max: number): boolean {
    if (isNaN(min) || isNaN(max)) return false;
    if (min < 0 || max < 0) {
      return false;
    }
    return min <= max;
  }

  function change(min: number, max: number) {
    if (!validate(min, max)) return openError("Errore", "Range non valido");
    onChange(new Range(min, max));
  }

  return (
    <div>
      <ErrorNotificationToast
        durationInMs={2000}
        title={errorBag.title}
        description={errorBag.description}
        close={closeError}
        show={errorBag.show}
      />
      <Label className={"mb-2"}>{label}</Label>
      <div className={"flex items-center space-x-2"}>
        {hideMin ? null : (
          <CardInputWithLabel
            name={"Min"}
            value={v.min.toString()}
            onChange={(min) => {
              change(parseInt(min), v.max);
            }}
          />
        )}
        <CardInputWithLabel
          name={"Max"}
          value={v.max.toString()}
          onChange={(max) => {
            change(v.min, parseInt(max));
          }}
        />
      </div>
    </div>
  );
}
