export enum EventType {
  LUNCH = "LUNCH",
  DINNER = "DINNER",
  ANIMALS = "ANIMALS",
  STUDY = "STUDY",
  BREAKFAST = "BREAKFAST",
  APERITIF = "APERITIF",
  BUSINESS = "BUSINESS",
  CARRALLIES = "CARRALLIES",
  PHOTOGRAPHY = "PHOTOGRAPHY",
  VIRTUAL_EVENT = "VIRTUAL_EVENT",
  PARTY = "PARTY",
  FILM = "FILM",
  SPORT = "SPORT",
  PHILOSOPHY = "PHILOSOPHY",
  GAMES = "GAMES",
  ART_WORKSHOP = "ART_WORKSHOP",
  JAM_SESSION = "JAM_SESSION",
  FESTIVAL = "FESTIVAL",
  DISCO = "DISCO",
  EXCURSION = "EXCURSION",
  UNKNOWN = "UNKNOWN",
}
