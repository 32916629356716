import React, { useRef, useState } from "react";
import { WrongFileTypeException } from "../Exception/WrongFileTypeException";
import { Modal } from "./Modal";
import { PrimarySolidButton } from "./PrimarySolidButton";
import { TextInput } from "./TextInput";
import { isUrl } from "../UrlUtils/isUrl";
import { ErrorNotificationToast } from "./ErrorNotificationToast";
import { useErrorBag } from "./useErrorBag";

export type ImageSrcAndFileBag = {
  file: File;
  src: string;
};

type Props = {
  value?: ImageSrcAndFileBag;
  onChange: (v: ImageSrcAndFileBag) => void;
  onError?: (e: Error) => void;
};

export function ImageInput({ value, onChange, onError }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { errorBag, openError, closeError } = useErrorBag();

  function readImage(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        if (
          !event.target ||
          !event.target.result ||
          typeof event.target.result !== "string"
        )
          return reject(`Failed to load image`);
        return resolve(event.target.result);
      });
      reader.readAsDataURL(file);
    });
  }

  function onDrop(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();
    (async () => {
      try {
        const fileList = e.dataTransfer.files;
        if (!fileList || fileList.length <= 0)
          return onError?.(new Error(`Empty file list`));
        const file = fileList[0];
        if (!file.type.includes("image"))
          return onError?.(
            new WrongFileTypeException(`Wrong file type: ${file.type}`)
          );

        const imgSrc = await readImage(file);
        onChange({
          file: file,
          src: imgSrc,
        });
      } catch (e) {
        if (e instanceof Error) onError?.(e);
      }
    })();
  }

  async function handleUrl(url: string) {
    if (!isUrl(url))
      return openError("URL non valido", "L'URL che hai inserito non è valido");
    const response = await fetch(url);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });
    onChange({
      src: url,
      file: file,
    });
    setShowModal(false);
    setUrl("");
  }

  async function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (!files || files.length <= 0) return;
    const file = files[0];
    if (!file.type.includes("image"))
      return onError?.(
        new WrongFileTypeException(`Wrong file type: ${file.type}`)
      );
    const imgSrc = await readImage(file);
    onChange({
      file: file,
      src: imgSrc,
    });
    setShowModal(false);
  }

  return (
    <>
      <ErrorNotificationToast
        close={() => closeError()}
        description={errorBag.description}
        durationInMs={2000}
        show={errorBag.show}
        title={errorBag.title}
      />
      <Modal
        open={showModal}
        close={() => setShowModal(false)}
        title={"Carica immagine"}
      >
        <div className={"flex flex-col space-y-4"}>
          <input
            ref={inputRef}
            type={"file"}
            onChange={onFileChange}
            className={"invisible w-px h-px"}
          />
          <div>
            <div className={"flex flex-col space-y-2"}>
              <TextInput
                placeholder={"Inserisci URL"}
                value={url}
                onChange={(v) => setUrl(v)}
              />
              <div className={"flex justify-center"}>
                <PrimarySolidButton
                  onClick={() => handleUrl(url)}
                  label={"Carica"}
                />
              </div>
            </div>
          </div>
          <div className={"flex justify-center"}>
            <p className={"text-center"}>Oppure</p>
          </div>
          <div className={"flex justify-center"}>
            <PrimarySolidButton
              onClick={() => {
                inputRef.current?.click();
              }}
              label={"Scegli file"}
            />
          </div>
        </div>
      </Modal>
      <div
        onClick={() => setShowModal(true)}
        style={{
          backgroundImage: value?.src ? `url("${value.src}")` : undefined,
        }}
        className={
          "w-20 h-28 rounded-md cursor-pointer bg-slate-200 hover:bg-slate-400 bg-cover bg-center bg-no-repeat"
        }
        onDragOver={(e) => {
          e.preventDefault();
          e.dataTransfer.dropEffect = "copy";
        }}
        onDrop={onDrop}
      />
    </>
  );
}
