export function SimpleErrorMessage({
  error,
  className,
}: {
  error?: string;
  className?: string;
}) {
  if (!error) return null;
  return (
    <span
      className={[
        "text-red-600 text-sm text-center flex-shrink flex-grow-0",
        className ?? "",
      ].join(" ")}
    >
      {error}
    </span>
  );
}
