import { Label } from "./Label";
import { useErrorBag } from "./useErrorBag";
import { ErrorNotificationToast } from "./ErrorNotificationToast";
import { CardInputWithLabel } from "./CardInputWithLabel";

export type Duration = {
  days: number;
  hours: number;
  minutes: number;
};
type Props = {
  value?: Duration;
  onChange: (v: Duration) => void;
  label: string;
};

export function DurationInput({ value, onChange, label }: Props) {
  const { errorBag, openError, closeError } = useErrorBag();
  const v = value ?? {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  function validateDuration(v: Duration): boolean {
    if (isNaN(v.days) || isNaN(v.hours) || isNaN(v.minutes)) return false;
    if (v.days < 0 || v.hours < 0 || v.minutes < 0) {
      return false;
    }
    return !(v.hours > 23 || v.minutes > 59);
  }

  function change(duration: Partial<Duration>) {
    const newDuration: Duration = {
      days: duration.days ?? v.days,
      hours: duration.hours ?? v.hours,
      minutes: duration.minutes ?? v.minutes,
    };
    if (!validateDuration(newDuration)) {
      return openError("Errore", "Durata non valida");
    }
    onChange(newDuration);
  }

  return (
    <div>
      <ErrorNotificationToast
        durationInMs={2000}
        title={errorBag.title}
        description={errorBag.description}
        close={closeError}
        show={errorBag.show}
      />
      <Label className={"mb-2"}>{label}</Label>
      <div className={"flex space-x-2"}>
        <CardInputWithLabel
          value={v.days.toString()}
          onChange={(v) => change({ days: parseInt(v) })}
          name={"Giorni"}
        />
        <CardInputWithLabel
          value={v.hours.toString()}
          onChange={(v) => change({ hours: parseInt(v) })}
          name={"Ore"}
        />
        <CardInputWithLabel
          value={v.minutes.toString()}
          onChange={(v) => change({ minutes: parseInt(v) })}
          name={"Minuti"}
        />
      </div>
    </div>
  );
}
