import { useNavigate } from "react-router-dom";
import React from "react";

type Props = {
  isActive: boolean;
  href: string;
  label: string;
  icon?: React.ReactNode;
  onNavigate?: () => void;
};
export function MenuLink(props: Props) {
  const navigate = useNavigate();
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        navigate(props.href);
        if (props.onNavigate) props.onNavigate();
      }}
      className={[
        "p-3 hover:bg-slate-200 rounded-lg cursor-pointer",
        props.isActive ? "bg-slate-100" : "",
      ].join(" ")}
    >
      <a
        className={[
          props.isActive ? "text-gray-800" : "text-gray-600",
          "text-lg flex items-center",
        ].join(" ")}
        onClick={(e) => {
          e.preventDefault();
          navigate(props.href);
        }}
        href={props.href}
      >
        {props.icon}
        <span className={[props.icon ? "ml-2" : ""].join(" ")}>
          {props.label}
        </span>
      </a>
    </div>
  );
}
