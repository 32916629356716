import { gql, useQuery } from "@apollo/client";
import { EventDTO } from "./EventDTO";

const GET_EVENT_BY_ID = gql`
  query getEventById($id: ID!) {
    getEventById(id: $id) {
      ... on Event {
        id
        title
        gallery {
          src
        }
        description
        date
        type
        isPrivate
      }
    }
  }
`;

type GetEventByIdResponse = {
  getEventById: EventDTO;
};

type GetEventByIdInput = {
  id: string;
};
export function useEventById(id?: string) {
  const { data, loading } = useQuery<GetEventByIdResponse, GetEventByIdInput>(
    GET_EVENT_BY_ID,
    {
      skip: !id,
      variables: {
        id: id as string,
      },
    }
  );

  return {
    event: data?.getEventById,
    loading,
  };
}
