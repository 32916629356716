import {
  FieldFunctionOptions,
  FieldPolicy,
  gql,
  useQuery,
} from "@apollo/client";
import { EventDTO } from "../EventDTO";
import { useCallback, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { FilterEventByOpennessType } from "./FilterEventByOpenessType";

const GET_MY_EVENTS = gql`
  query getMyEventsSortedByDate(
    $nextToken: String
    $limit: Int
    $afterDate: DateTime
    $excludeCancelled: Boolean
    $filterByOpennessType: FilterEventByOpennessType
  ) {
    getMyEventsSortedByDate(
      nextToken: $nextToken
      limit: $limit
      afterDate: $afterDate
      excludeCancelled: $excludeCancelled
      filterByOpennessType: $filterByOpennessType
    ) {
      events {
        ... on Event {
          id
          type
          date
          title
          description
          gallery {
            src
          }
          isPrivate
          addressString
          endAt
        }
        ... on VirtualEvent {
          id
          type
          date
          title
          description
          gallery {
            src
          }
          isPrivate
          endAt
        }
      }
      hasMore
      nextToken
    }
  }
`;
type GetMyEventsResult = {
  events: EventDTO[];
  hasMore: boolean;
  nextToken?: string;
};
type GetMyEventsResponse = {
  getMyEventsSortedByDate: GetMyEventsResult;
};

type GetMyEventsInput = {
  nextToken?: string;
  limit?: number;
  afterDate?: Date;
  excludeCancelled?: boolean;
  filterByOpennessType?: FilterEventByOpennessType;
};

export const GetMyEventsFieldsPolicy: {
  getMyEventsSortedByDate: FieldPolicy<
    GetMyEventsResult,
    GetMyEventsResult,
    FieldFunctionOptions<GetMyEventsInput, GetMyEventsInput>
  >;
} = {
  getMyEventsSortedByDate: {
    keyArgs: ["filterByOpennessType"],
    merge(existing, incoming, { readField }) {
      return {
        nextToken: incoming.nextToken,
        events: _.sortedUniqBy(
          _.sortBy([...(existing?.events ?? []), ...incoming.events], (e) => {
            return dayjs(readField("date", e) as string).valueOf();
          }),
          (e) => readField("id", e)
        ),
        hasMore: incoming.hasMore ?? false,
      };
    },
  },
};

export function useMyEvents({
  pageSize = 30,
  afterDate,
  excludeCancelled,
  filterByOpennessType,
}: {
  pageSize?: number;
  afterDate?: Date;
  excludeCancelled?: boolean;
  filterByOpennessType?: FilterEventByOpennessType;
}) {
  const { data, loading, fetchMore, refetch } = useQuery<
    GetMyEventsResponse,
    GetMyEventsInput
  >(GET_MY_EVENTS, {
    variables: {
      limit: pageSize,
      afterDate,
      excludeCancelled,
      filterByOpennessType,
    },
  });
  const [fetchingMore, setFetchingMore] = useState(false);

  const hasMore = data?.getMyEventsSortedByDate.hasMore ?? false;
  const nextToken = data?.getMyEventsSortedByDate.nextToken;

  const fetchNextPage = useCallback(async () => {
    if (!hasMore) {
      return console.warn(
        "Called fetchNextPage on a page without next page. This is a no op"
      );
    }
    if (!nextToken) throw new Error(`Missing next token when hasMore is true`);
    try {
      setFetchingMore(true);
      await fetchMore({
        variables: {
          nextToken,
          limit: pageSize,
        },
      });
    } finally {
      setFetchingMore(false);
    }
  }, [hasMore, nextToken, pageSize, fetchMore]);

  return {
    events: data?.getMyEventsSortedByDate.events ?? [],
    loading: loading,
    hasMore,
    fetchNextPage,
    fetchingMore,
    refetch,
  };
}
