import { Layout } from "../Layout/Layout";
import { TextArea } from "../UI/TextArea";
import { useMemo, useState } from "react";
import { Select, SelectValue } from "../UI/Select";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { useSendNotificationToAllUsers } from "./useSendNotificationToAllUsers";
import { SuccessNotificationToast } from "../UI/SuccessNotificationToast";
import { SearchEvent } from "../Event/SearchEvent";
import { useSendGoToEventNotificationToAllUsers } from "./useSendGoToEventNotificationToAllUsers";
import { ComboBoxValue } from "../UI/ComboBox";
enum PushType {
  Message = "message",
  GoToEvent = "goToEvent",
}
export function SendNotificationsScreen() {
  const [msg, setMessage] = useState("");
  const [showMsgError, setShowMsgError] = useState(false);
  const languages = useMemo(() => {
    return [
      {
        name: "Italiano",
        id: "it",
      },
      {
        name: "Inglese",
        id: "en",
      },
    ];
  }, []);
  const [lang, setLang] = useState<SelectValue>(languages[0]);
  const { sendNotificationToAllUsers, loading } =
    useSendNotificationToAllUsers();

  const {
    sendGoToEventNotificationToAllUsers,
    loading: sendingGoToEventNotification,
  } = useSendGoToEventNotificationToAllUsers();

  const [sowSuccessNotification, setShowSuccessNotification] = useState(false);
  const [notifiedUsersCount, setNotifiedUsersCount] = useState(0);
  const [showMissingEventError, setShowMissingEventError] = useState(false);

  const pushTypes = [
    {
      name: "Messaggio semplice",
      id: PushType.Message,
    },
    {
      name: "Vai all'evento",
      id: PushType.GoToEvent,
    },
  ];

  const [type, setType] = useState<SelectValue>(pushTypes[0]);
  const [selectedEvent, setSelectedEvent] = useState<ComboBoxValue>();

  async function send(): Promise<void> {
    if (!msg || !msg.length) {
      return setShowMsgError(true);
    }
    if (!lang) {
      return;
    }

    let usersNotifiedCount = 0;
    switch (type.id) {
      case PushType.Message: {
        const res = await sendNotificationToAllUsers(msg, lang.id);
        usersNotifiedCount = res.usersNotifiedCount;
        break;
      }
      case PushType.GoToEvent: {
        if (!selectedEvent) return setShowMissingEventError(true);
        const res = await sendGoToEventNotificationToAllUsers(
          selectedEvent.id,
          msg,
          lang.id
        );
        usersNotifiedCount = res.usersNotifiedCount;
        break;
      }
    }

    setNotifiedUsersCount(usersNotifiedCount);
    setShowSuccessNotification(true);
    setMessage("");
    setSelectedEvent(undefined);
  }

  function closeSuccess() {
    setShowSuccessNotification(false);
  }
  return (
    <Layout showMenu>
      <SuccessNotificationToast
        title={"Notifica inviata"}
        show={sowSuccessNotification}
        close={closeSuccess}
        description={`Messaggio inviato a ${notifiedUsersCount} utenti`}
        durationInMs={5000}
      />
      <div className={"p-4"}>
        <h1 className={"font-bold text-2xl"}>Notifica tutti gli utenti</h1>
        <div className={"mt-4 relative z-40"}>
          <label>Lingua</label>
          <div className={"mt-2"}>
            <Select selected={lang} setSelected={setLang} options={languages} />
          </div>
        </div>
        <div className={"mt-4 relative z-30"}>
          <label>Tipologia di notifica</label>
          <div className={"mt-2"}>
            <Select selected={type} setSelected={setType} options={pushTypes} />
          </div>
        </div>
        {type.id === PushType.GoToEvent ? (
          <div className={"mt-4 relative z-20"}>
            <label>Cerca l'evento</label>
            <SearchEvent
              errorString={
                showMissingEventError ? "Devi selezionare un evento" : undefined
              }
              setSelected={(v) => {
                setSelectedEvent(v);
                setShowMissingEventError(false);
              }}
              selected={selectedEvent}
            />
          </div>
        ) : null}
        <div className={"mt-4"}>
          <label>Messaggio</label>
          <div className={"mt-2"}>
            <TextArea
              errorString={showMsgError ? "Devi specificare un messaggio" : ""}
              value={msg}
              onChange={(v) => {
                setShowMsgError(false);
                setMessage(v);
              }}
              maxCharacters={200}
              placeholder={
                "Scrivi qualcosa per notificare tutti gli utenti nella lingua selezionata"
              }
              rows={4}
            />
          </div>
        </div>
        <div className={"mt-4 flex justify-center"}>
          <PrimarySolidButton
            onClick={send}
            loading={loading || sendingGoToEventNotification}
            label={"Invia"}
          />
        </div>
      </div>
    </Layout>
  );
}
