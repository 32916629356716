import { GoogleAddressComponent } from "./GoogleAddressComponent";

export class GoogleAddressComponentReader {
  constructor(private readonly addressComponents: GoogleAddressComponent[]) {}

  private findComponentByType(
    type: string
  ): GoogleAddressComponent | undefined {
    return this.addressComponents.find((c) => c.types.includes(type));
  }

  public getStreet(): string {
    const component = this.findComponentByType("route");
    return component?.long_name ?? "";
  }

  public getStreetNumber(): string {
    const component = this.findComponentByType("street_number");
    return component?.long_name ?? "";
  }

  public getRegion(): string {
    const component = this.findComponentByType("administrative_area_level_1");
    return component?.long_name ?? "";
  }

  public getProvince(): string {
    const component = this.findComponentByType("administrative_area_level_2");
    return component?.long_name ?? "";
  }

  public getShortProvince(): string {
    const component = this.findComponentByType("administrative_area_level_2");
    return component?.short_name ?? "";
  }

  public getCap(): string {
    const component = this.findComponentByType("postal_code");
    return component?.long_name ?? "";
  }

  public getCountry(): string {
    const component = this.findComponentByType("country");
    return component?.long_name ?? "";
  }

  public getCity(): string {
    const component = this.findComponentByType("administrative_area_level_3");
    return component?.long_name ?? "";
  }
}
