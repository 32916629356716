// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { FirebaseAppName } from "./FirebaseAppName";
function safelyGetApp(name: string) {
  try {
    return getApp(name);
  } catch (e) {
    return null;
  }
}
export function initFirebaseApp() {
  const oldApp = safelyGetApp(FirebaseAppName);
  if (oldApp) {
    console.log(`Firebase app ${FirebaseAppName} already initialized`);
    return;
  }

  console.log(`Initializing Firebase app ${FirebaseAppName}`);
  const firebaseConfig = {
    apiKey: "AIzaSyDfTFmPfnrl3ml8vK5NC2_BVRNfw9FQE9E",
    authDomain: "dating-app-21650.firebaseapp.com",
    databaseURL:
      "https://dating-app-21650-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "dating-app-21650",
    storageBucket: "dating-app-21650.appspot.com",
    messagingSenderId: "365234398413",
    appId: "1:365234398413:web:e1fe0ff1e380553c507584",
    measurementId: "G-JYKRW0CQ1R",
  };

  initializeApp(firebaseConfig, FirebaseAppName);
}
