import React from "react";

const MenuContext = React.createContext<{
  openedOnMobile: boolean;
  openMenu: () => void;
  closeMenu: () => void;
}>({
  openedOnMobile: false,
  openMenu: () => {
    //void
  },
  closeMenu: () => {
    //void
  },
});

export { MenuContext };
