import { gql, useQuery } from "@apollo/client";

const SEARCH_EVENTS = gql`
  query searchEvents($search: String!) {
    searchEvents(search: $search) {
      ... on VirtualEventInfo {
        id
        gallery {
          src
        }
        title
      }
      ... on EventInfo {
        id
        gallery {
          src
        }
        title
      }
    }
  }
`;

type EventInfoDTO = {
  id: string;
  gallery: {
    src: string;
  }[];
  title: string;
};
type Response = {
  searchEvents: EventInfoDTO[];
};
type Input = {
  search: string;
};
export function useSearchEvents(search: string) {
  const { data, loading } = useQuery<Response, Input>(SEARCH_EVENTS, {
    skip: !search || !search.length,
    variables: {
      search: search,
    },
  });

  return {
    events: data?.searchEvents ?? [],
    loading,
  };
}
