import { TextInput, TextInputProps } from "./TextInput";
import { Label } from "./Label";

type Props = TextInputProps & {
  label: string;
};
export function TextInputWithLabel({ label, ...textInputProps }: Props) {
  return (
    <div className={"flex flex-col"}>
      <Label className={"mb-2 block"}>{label}</Label>
      <TextInput {...textInputProps} />
    </div>
  );
}
