type Props = {
  title: string;
  description: string;
};
export function Warning({ title, description }: Props) {
  return (
    <div className={"p-4 bg-yellow-200 rounded-lg"}>
      <h4 className={"text-yellow-800"}>{title}</h4>
      <p className={"text-yellow-800"}>{description}</p>
    </div>
  );
}
