import { UserDTO } from "./UserDTO";
import { Navigate } from "react-router-dom";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  user?: UserDTO;
}>;
export function ProtectedRoute(props: Props) {
  if (!props.user) return <Navigate to={"/login"} />;
  return <>{props.children ?? null}</>;
}
