import React from "react";
import { WhiteLoadingIndicator } from "./WhiteLoadingIndicator";
import { classNames } from "./CssUtils/classNames";

type Props = {
  label: string;
  onClick?: () => void;
  id?: string;
  loading?: boolean;
  disabled?: boolean;
};

const DangerButton = React.forwardRef<HTMLAnchorElement, Props>(
  function DangerButtonForwarded(props, ref) {
    return (
      <a
        ref={ref}
        id={props.id}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (props.disabled) return;
          props.onClick?.();
        }}
        className={classNames([
          "rounded-md px-8 py-2 cursor-pointer flex items-center justify-center  whitespace-nowrap",
          props.disabled
            ? "bg-slate-200 text-slate-600 cursor-not-allowed"
            : "bg-red-600 text-white hover:bg-red-800",
        ])}
      >
        <span className={"mr-2"}>{props.label}</span>
        {props.loading ? <WhiteLoadingIndicator size={"small"} /> : null}
      </a>
    );
  }
);

export { DangerButton };
