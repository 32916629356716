import React from "react";
import { UserDTO } from "./UserDTO";

type AuthCtxType = {
  user?: UserDTO;
  logout: () => void;
};
const AuthCtx = React.createContext<AuthCtxType>({
  logout: () => {
    //void
  },
});

export { AuthCtx };
