import { TextArea, TextAreaProps } from "./TextArea";
import { Label } from "./Label";

type Props = TextAreaProps & {
  label: string;
};
export function TextAreaWithLabel({ label, ...textAreaProps }: Props) {
  return (
    <div>
      <Label className={"mb-2 block"}>{label}</Label>
      <TextArea {...textAreaProps} />
    </div>
  );
}
