import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { SimpleErrorMessage } from "./SimpleErrorMessage";
import { classNames } from "./CssUtils/classNames";
import { PrimaryLoadingIndicator } from "./PrimaryLoadingIndicator";

export type SelectValue = {
  name: string;
  id: string;
};

type Props = {
  selected?: SelectValue;
  setSelected: (v: SelectValue) => void;
  options: SelectValue[];
  placeholder?: string;
  errorString?: string;
  noDefaultMargin?: boolean;
  loading?: boolean;
};
export function Select({
  selected,
  setSelected,
  options,
  placeholder,
  errorString,
  noDefaultMargin,
  loading,
}: Props) {
  return (
    <div className={"flex flex-col items-center"}>
      <Listbox value={selected} onChange={setSelected}>
        <div
          className={classNames([
            "relative w-full",
            noDefaultMargin ? "" : "mt-1",
          ])}
        >
          <Listbox.Button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
            }}
            className="relative flex items-center justify-between w-full cursor-default cursor-pointer rounded-lg bg-white py-2 px-3 text-left border border-slate-200 shadow-md shadow-slate-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          >
            <span className="block truncate">
              {selected?.name ?? placeholder}
            </span>
            <div className={"flex items-center"}>
              <span className="pointer-events-none inset-y-0 right-0 flex items-center">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
              {loading ? <PrimaryLoadingIndicator size={"small"} /> : null}
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-primary text-white" : "text-gray-900"
                    }`
                  }
                  onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                    e.stopPropagation();
                  }}
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.name}
                      </span>
                      {selected ? (
                        <span
                          className={[
                            `absolute inset-y-0 left-0 flex items-center pl-3`,
                            active ? "text-white" : "text-primary",
                          ].join(" ")}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <SimpleErrorMessage className={"mt-2"} error={errorString} />
    </div>
  );
}
