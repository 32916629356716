import { BaseFormFieldProps } from "./BaseFormFieldProps";
import { FieldValues, useController } from "react-hook-form";
import { PlacesAutocompleteInput } from "../UI/PlacesAutocompleteInput";
import { JustMeetAddress } from "../Geocode/JustMeetAddress";

type Props<TFieldValues> = BaseFormFieldProps<
  TFieldValues,
  {
    label: string;
    msgInCaseOfError?: string;
  }
>;

export function FormPlaceAutocompleteField<TFieldValues extends FieldValues>({
  fieldName,
  control,
  required,
  label,
  msgInCaseOfError,
}: Props<TFieldValues>) {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name: fieldName,
    control: control,
    rules: {
      required: required,
      validate: (value) => {
        return JustMeetAddress.fromDTO(value).isComplete();
      },
    },
  });

  return (
    <PlacesAutocompleteInput
      errorString={invalid ? msgInCaseOfError : undefined}
      value={value}
      onChange={onChange}
      label={label}
    />
  );
}
