import { PropsWithChildren } from "react";
import { AuthCtx } from "./AuthCtx";
import { useMe } from "./useMe";
import { FullScreenLoading } from "../UI/FullScreenLoading";
import { signOut } from "firebase/auth";
import { getFirebaseAuth } from "../Firebase/getFirebaseAuth";

type Props = PropsWithChildren<unknown>;
export function AuthCtxProvider(props: Props) {
  const { loading, user } = useMe();
  if (!user && loading) return <FullScreenLoading />;
  async function logout() {
    await signOut(getFirebaseAuth());
    window.location.href = "/login";
  }
  return (
    <AuthCtx.Provider
      value={{
        user,
        logout,
      }}
    >
      {props.children ?? null}
    </AuthCtx.Provider>
  );
}
