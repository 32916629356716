import { Layout } from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { PrimarySolidButton } from "../../UI/PrimarySolidButton";
import { FormTextField } from "../../Form/FormTextField";
import { FormTextAreaField } from "../../Form/FormTextAreaField";
import { ErrorNotificationToast } from "../../UI/ErrorNotificationToast";
import { useErrorBag } from "../../UI/useErrorBag";
import dayjs from "dayjs";
import { FormDateTimeField } from "../../Form/FormDateTimeField";
import { FormSelectField } from "../../Form/FormSelectField";
import { SelectValue } from "../../UI/Select";
import { usePossibleEventTypes } from "./usePossibleEventTypes";
import { FullScreenLoading } from "../../UI/FullScreenLoading";
import { FormDurationInput } from "../../Form/FormDurationInput";
import { Duration } from "../../UI/DurationInput";
import { FormRangeField } from "../../Form/FormRangeField";
import { Range } from "../../Range";
import { FormPlaceAutocompleteField } from "../../Form/FormPlaceAutocompleteField";
import {
  JustMeetAddress,
  JustMeetAddressDTO,
} from "../../Geocode/JustMeetAddress";
import { EventType } from "../EventType";
import { useCreateEvent } from "./useCreateEvent";
import { FormGalleryInput } from "../../Form/FormGalleryInput";
import { ImageSrcAndFileBag } from "../../UI/ImageInput";
import { SuccessNotificationToast } from "../../UI/SuccessNotificationToast";
import { useSuccessBag } from "../../UI/useSuccessBag";

type FormFields = {
  title: string;
  description: string;
  date: string;
  eventType: SelectValue;
  duration: Duration;
  minMaxAge: Range;
  minMaxPeople: Range;
  place: JustMeetAddressDTO;
  gallery: ImageSrcAndFileBag[];
};

export function CreateEventScreen() {
  const { createEvent, loading: creatingEvent } = useCreateEvent();
  const { successBag, openSuccess, closeSuccess } = useSuccessBag();
  const { handleSubmit, control, reset } = useForm<FormFields>({
    defaultValues: {
      date: dayjs().add(1, "day").startOf("date").hour(12).toISOString(),
      title: "",
      description: "",
      minMaxAge: {
        min: 18,
        max: 50,
      },
      minMaxPeople: {
        min: 0,
        max: 200,
      },
      gallery: [],
    },
  });

  const { loading, types } = usePossibleEventTypes();

  const { errorBag, closeError, openError } = useErrorBag();

  async function onSubmit(v: FormFields) {
    try {
      await createEvent({
        title: v.title,
        description: v.description,
        date: dayjs(v.date).toDate(),
        type: v.eventType.id as EventType,
        duration: v.duration,
        minMaxAge: v.minMaxAge,
        minMaxPeople: v.minMaxPeople,
        address: JustMeetAddress.fromDTO(v.place),
        gallery: v.gallery.map((v) => v.file),
      });
      reset();
      openSuccess("Operazione completata", "Evento creato con successo");
    } catch (e) {
      console.error(e);
      openError("Errore", "Qualcosa è andato storto");
    }
  }

  if (loading) return <FullScreenLoading />;

  return (
    <Layout showMenu>
      <SuccessNotificationToast
        durationInMs={2000}
        title={successBag.title}
        description={successBag.description}
        close={closeSuccess}
        show={successBag.show}
      />
      <ErrorNotificationToast
        close={() => closeError()}
        description={errorBag.description}
        durationInMs={2000}
        show={errorBag.show}
        title={errorBag.title}
      />
      <div className={"p-4"}>
        <div className={"grid gap-6"}>
          <div>
            <FormSelectField
              msgInCaseOfError={"Devi selezionare un tipo di evento"}
              required
              placeholder={"Seleziona un tipo di evento"}
              control={control}
              fieldName={"eventType"}
              label={"Tipologia evento"}
              options={types
                .filter((t) => t.type !== EventType.VIRTUAL_EVENT)
                .map((t) => ({
                  name: t.name,
                  id: t.type,
                }))}
            />
          </div>
          <div>
            <FormTextField
              required
              msgInCaseOfError={"Devi specificare un titolo"}
              label={"Titolo"}
              fieldName={"title"}
              control={control}
            />
          </div>
          <div>
            <FormTextAreaField
              label={"Descrizione"}
              fieldName={"description"}
              control={control}
            />
          </div>
          <div>
            <FormPlaceAutocompleteField
              msgInCaseOfError={
                "Devi specificare un indirizzo valido e completo"
              }
              label={"Indirizzo"}
              fieldName={"place"}
              control={control}
            />
          </div>
          <div className={"mr-auto"}>
            <FormGalleryInput
              validate={(v) => {
                return !!v && (v as ImageSrcAndFileBag[]).length > 0;
              }}
              msgInCaseOfError={"Devi inserire almeno un'immagine"}
              required
              label={"Immagini"}
              control={control}
              fieldName={"gallery"}
            />
          </div>
          <div>
            <FormDateTimeField
              fieldName={"date"}
              control={control}
              label={"Data"}
            />
          </div>
          <div>
            <FormDurationInput
              label={"Durata"}
              fieldName={"duration"}
              control={control}
            />
          </div>
          <div>
            <FormRangeField
              label={"Età"}
              fieldName={"minMaxAge"}
              control={control}
            />
          </div>
          <div>
            <FormRangeField
              hideMin
              label={"Persone"}
              fieldName={"minMaxPeople"}
              control={control}
            />
          </div>
        </div>

        <div className={"flex justify-center mt-8"}>
          <PrimarySolidButton
            loading={creatingEvent}
            onClick={() => {
              handleSubmit(onSubmit)().catch((e) => {
                console.error("e", e);
              });
            }}
            label={"Crea evento"}
          />
        </div>
      </div>
    </Layout>
  );
}
