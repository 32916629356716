type Props = {
  size: "small" | "large";
};
export function PrimaryLoadingIndicator(props: Props) {
  return (
    <div
      className={[
        "border-t-primary border-2 border-primary/20 animate-spin rounded-full",
        props.size === "large" ? "w-12 h-12" : "w-4 h-4",
      ].join(" ")}
    />
  );
}
