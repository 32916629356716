import { gql, useQuery } from "@apollo/client";
import { UserDTO } from "./UserDTO";

const ME_QUERY = gql`
  query me {
    me {
      id
      email
      name
    }
  }
`;
type MeQueryResponse = {
  me: UserDTO;
};

export function useMe() {
  const { data, loading } = useQuery<MeQueryResponse>(ME_QUERY);

  return {
    user: data?.me,
    loading,
  };
}
