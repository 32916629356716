import { EventDTO } from "../EventDTO";
import {
  ColumnFiltersState,
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { DebouncedTextInput } from "../../UI/DebouncedTextInput";
import { rankItem } from "@tanstack/match-sorter-utils";
import { useState } from "react";
import dayjs from "dayjs";
import { classNames } from "../../UI/CssUtils/classNames";
import { PrimarySolidButton } from "../../UI/PrimarySolidButton";
import { DangerButton } from "../../UI/DangerButton";
import { useSetEventAsPinned } from "./useSetEventAsPinned";
import { useUnsetEventAsPinned } from "./useUnsetEventAsPinned";

type Props = {
  events: EventDTO[];
};
export function EventsTable({ events }: Props) {
  const columnHelper = createColumnHelper<EventDTO>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "date",
      desc: false,
    },
  ]);

  const columns = [
    columnHelper.accessor("title", {
      header: "Titolo",
      enableSorting: false,
    }),
    columnHelper.accessor("addressString", {
      header: "Indirizzo",
      enableSorting: false,
    }),
    columnHelper.accessor("date", {
      header: "Data",
      cell: ({ cell }) => dayjs(cell.getValue()).format("ddd DD MMM YYYY"),
      enableColumnFilter: false,
    }),
    columnHelper.display({
      header: "Action",
      cell: function ActionCell({ row }) {
        const { setEventAsPinned, loading: pinning } = useSetEventAsPinned();
        const { unSetEventAsPinned, loading: unPinning } =
          useUnsetEventAsPinned();
        return (
          <div>
            {row.original.isStarred ? (
              <DangerButton
                loading={unPinning}
                onClick={async () => await unSetEventAsPinned(row.original.id)}
                label={"Rimuovi Pin"}
              />
            ) : (
              <PrimarySolidButton
                loading={pinning}
                onClick={async () => await setEventAsPinned(row.original.id)}
                label={"Pin"}
              />
            )}
          </div>
        );
      },
    }),
  ];

  const fuzzyFilter: FilterFn<EventDTO> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable<EventDTO>({
    data: events,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      sorting,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <div className={"overflow-x-scroll"}>
      <table className={"table-auto min-w-[100%] rounded-lg overflow-hidden"}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className={"bg-blue-200/30"} key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className={"border border-slate-200 p-4"}
                  key={header.id}
                  colSpan={header.colSpan}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      onClick={(e) => {
                        if (!header.column.getCanSort()) return;
                        header.column.getToggleSortingHandler()?.(e);
                      }}
                      className={classNames([
                        "flex",
                        header.column.getCanSort() ? "cursor-pointer" : "",
                      ])}
                    >
                      <div className={"flex-1"}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() ? (
                          <div className={"mt-4"}>
                            <DebouncedTextInput
                              placeholder={`Cerca... ${
                                header.column.getFacetedUniqueValues().size
                              }`}
                              onChange={(v) => {
                                header.column.setFilterValue(v);
                              }}
                              value={
                                (header.column.getFilterValue() ?? "") as string
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                      {header.column.getCanSort() ? (
                        <div>
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      ) : null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr className={"even:bg-blue-200/30"} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className={"border border-slate-200 p-4"} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}
