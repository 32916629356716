export type TextAreaProps = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  rows?: number;
  maxCharacters?: number;
  errorString?: string;
};
export function TextArea({
  maxCharacters,
  rows,
  placeholder,
  value,
  onChange,
  errorString,
}: TextAreaProps) {
  const valLen = value?.length ?? 0;
  return (
    <div className={"w-full"}>
      <textarea
        rows={rows}
        className={[
          "border rounded-lg w-full p-2",
          errorString ? "border-red-600" : "border-slate-200",
        ].join(" ")}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
      />
      {errorString ? (
        <p className={"text-red-600 text-sm"}>{errorString}</p>
      ) : null}
      {maxCharacters ? (
        <div className={"w-full flex justify-end"}>
          <p
            className={[
              "text-sm",
              valLen > maxCharacters
                ? "text-red-600 font-bold"
                : "text-gray-400",
            ].join(" ")}
          >
            {valLen ?? 0} / {maxCharacters}
          </p>
        </div>
      ) : null}
    </div>
  );
}
