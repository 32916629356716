import { ImageInput, ImageSrcAndFileBag } from "./ImageInput";
import { Label } from "./Label";
import { useErrorBag } from "./useErrorBag";
import { ErrorNotificationToast } from "./ErrorNotificationToast";
import { WrongFileTypeException } from "../Exception/WrongFileTypeException";
import { SimpleErrorMessage } from "./SimpleErrorMessage";

type Props = {
  className?: string;
  label: string;
  onChange: (imageSrcAndFileBag: ImageSrcAndFileBag[]) => void;
  errorString?: string;
  value?: ImageSrcAndFileBag[];
};
export function GalleryInput({
  className,
  label,
  onChange: onChangeProp,
  errorString,
  value,
}: Props) {
  const images = new Map<number, ImageSrcAndFileBag>(
    value?.map((image, index) => [index, image]) ?? []
  );
  const { errorBag, openError, closeError } = useErrorBag();
  function onChange(index: number, value: ImageSrcAndFileBag) {
    images.set(index, value);
    onChangeProp(Array.from(images.values()));
  }

  function onError(e: Error) {
    if (e instanceof WrongFileTypeException) {
      openError("Errore", "Il file caricato non è un'immagine");
    } else {
      openError(
        "Errore",
        "Si è verificato un errore sconosciuto durante il caricamento dell'immagine"
      );
    }
  }

  return (
    <>
      <ErrorNotificationToast
        durationInMs={2000}
        title={errorBag.title}
        description={errorBag.description}
        close={closeError}
        show={errorBag.show}
      />
      <div>
        <Label className={"mb-2 block"}>{label}</Label>
        <div className={["grid grid-cols-6 gap-4", className ?? ""].join(" ")}>
          {Array.from({
            length: 6,
          }).map((_, index) => {
            return (
              <div key={index}>
                <ImageInput
                  onError={onError}
                  value={images.get(index)}
                  onChange={(value) => {
                    onChange(index, value);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className={"flex justify-center"}>
          <SimpleErrorMessage className={"mt-2"} error={errorString} />
        </div>
      </div>
    </>
  );
}
