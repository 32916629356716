import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { LoginScreen } from "./Authentication/LoginScreen";
import { TokenScreen } from "./Authentication/TokenScreen";
import { ApolloClientFactory } from "./ApolloClient/ApolloClientFactory";
import { ApolloProvider } from "@apollo/client";
import { OnlyNotLoggedRoute } from "./Authentication/OnlyNotLoggedRoute";
import { ProtectedRoute } from "./Authentication/ProtectedRoute";
import { AuthCtxProvider } from "./Authentication/AuthCtxProvider";
import { useAuthCtx } from "./Authentication/useAuthCtx";
import { SendNotificationsScreen } from "./Notification/SendNotificationsScreen";
import { CreateEventScreen } from "./Event/CreateEvent/CreateEventScreen";
import { MyEventListScreen } from "./Event/EditEvent/MyEventListScreen";
import { EditEventScreen } from "./Event/EditEvent/EditEventScreen";
import { AllEventsScreen } from "./Event/AllEventScreen/AllEventsScreen";

function RootComponent() {
  const { user } = useAuthCtx();
  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<Navigate to={"/notifications"} />} />
        <Route
          path="/login"
          element={
            <OnlyNotLoggedRoute user={user} ifLoggedRedirectTo={"/"}>
              <LoginScreen />
            </OnlyNotLoggedRoute>
          }
        />
        <Route
          path="/token"
          element={
            <ProtectedRoute user={user}>
              <TokenScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute user={user}>
              <SendNotificationsScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-event"
          element={
            <ProtectedRoute user={user}>
              <CreateEventScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-events"
          element={
            <ProtectedRoute user={user}>
              <MyEventListScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-event/:eventId"
          element={
            <ProtectedRoute user={user}>
              <EditEventScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-events"
          element={
            <ProtectedRoute user={user}>
              <AllEventsScreen />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

function App() {
  const client = useMemo(() => new ApolloClientFactory().create(), []);
  return (
    <ApolloProvider client={client}>
      <AuthCtxProvider>
        <RootComponent />
      </AuthCtxProvider>
    </ApolloProvider>
  );
}

export default App;
