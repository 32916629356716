import Axios from "axios";
import { JustMeetAddress } from "./JustMeetAddress";
import { GoogleAddressComponentReader } from "./GoogleAddressComponentReader";
import { GoogleAddressComponent } from "./GoogleAddressComponent";

export async function geocodeByPlaceId(
  placeId: string,
  sessionToken: string,
  language = "it"
): Promise<JustMeetAddress> {
  const { data } = await Axios.get<{
    addressComponents: GoogleAddressComponent[];
    location: {
      lat: number;
      lng: number;
    };
  }>(
    `${process.env.REACT_APP_JUSTMEET_SERVER_BASE_URL}/geolocation/placeDetails`,
    {
      params: {
        placeId,
        sessionToken,
        fields: ["address_component", "geometry"],
        language,
      },
    }
  );
  const reader = new GoogleAddressComponentReader(data.addressComponents);
  return new JustMeetAddress(
    reader.getStreet(),
    reader.getStreetNumber(),
    reader.getRegion(),
    reader.getCity(),
    reader.getProvince(),
    reader.getShortProvince(),
    reader.getCap(),
    reader.getCountry(),
    placeId,
    data.location.lat,
    data.location.lng
  );
}
