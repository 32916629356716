import { BaseFormFieldProps } from "./BaseFormFieldProps";
import { GalleryInput } from "../UI/GalleryInput";
import { FieldValues, useController } from "react-hook-form";

type Props<TFieldValues> = BaseFormFieldProps<
  TFieldValues,
  {
    label: string;
    msgInCaseOfError?: string;
  }
>;
export function FormGalleryInput<TFieldValues extends FieldValues>({
  label,
  fieldName,
  control,
  required,
  msgInCaseOfError,
  validate,
}: Props<TFieldValues>) {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name: fieldName,
    control: control,
    rules: { required: required, validate },
  });
  return (
    <GalleryInput
      value={value}
      errorString={invalid ? msgInCaseOfError : undefined}
      label={label}
      onChange={onChange}
    />
  );
}
