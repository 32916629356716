import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { PrimaryLoadingIndicator } from "./PrimaryLoadingIndicator";

export type ComboBoxValue = {
  id: string;
  name: string;
  imageUrl?: string;
};
type Props = {
  selected?: ComboBoxValue;
  setSelected: (value: ComboBoxValue) => void;
  setQuery: (query: string) => void;
  items: ComboBoxValue[];
  query: string;
  loading?: boolean;
  errorString?: string;
  placeholder?: string;
};
export function ComboBox({
  selected,
  setSelected,
  setQuery,
  items,
  query,
  loading,
  errorString,
  placeholder,
}: Props) {
  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative mt-1">
        <div className={"flex items-center justify-between"}>
          <div className="relative w-full border border-slate-200 cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md shadow-slate-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              placeholder={placeholder}
              className="w-full border-none rounded-lg py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(val: ComboBoxValue | undefined) => val?.name ?? ""}
              onChange={(event) => setQuery(event.target.value)}
            />

            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              {loading ? (
                <PrimaryLoadingIndicator size={"small"} />
              ) : (
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </Combobox.Button>
          </div>
          {selected?.imageUrl ? (
            <img
              className={"h-20 rounded-md ml-4"}
              src={selected.imageUrl}
              alt={selected.name}
            />
          ) : null}
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          {(query !== "" || items.length > 0) && !loading ? (
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                items.map((item) => (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-primary text-white" : "text-gray-900"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <div className={"flex items-center justify-between"}>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {item.name}
                        </span>
                        {item.imageUrl ? (
                          <img
                            className={"h-20 rounded-md"}
                            src={item.imageUrl}
                            alt={item.name}
                          />
                        ) : null}
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          ) : (
            <div />
          )}
        </Transition>
        {errorString ? (
          <p className={"text-red-600 text-sm mt-2"}>{errorString}</p>
        ) : null}
      </div>
    </Combobox>
  );
}
