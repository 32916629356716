export class ComposableStringBuilder {
  private readonly parts: string[] = [];

  appendNotNull(s: string): ComposableStringBuilder {
    if (s && s.length > 0) this.parts.push(s);
    return this;
  }

  build() {
    return this.parts.join(", ");
  }
}
