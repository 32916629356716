import { PropsWithChildren, useState } from "react";
import { MenuContext } from "../Layout/MenuContext";

type Props = PropsWithChildren<unknown>;

export function MenuContextProvider(props: Props) {
  const [openedOnMobile, setOpenedOnMobile] = useState(false);

  function openMenu() {
    setOpenedOnMobile(true);
  }

  function closeMenu() {
    setOpenedOnMobile(false);
  }

  return (
    <MenuContext.Provider
      value={{
        openMenu,
        closeMenu,
        openedOnMobile,
      }}
    >
      {props.children ?? null}
    </MenuContext.Provider>
  );
}
