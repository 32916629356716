import { gql, useMutation } from "@apollo/client";
import { EventDTO } from "../EventDTO";

const SET_EVENT_AS_PINNED = gql`
  mutation setEventAsPinned($eventId: String!) {
    setEventAsPinned(eventId: $eventId) {
      ... on Event {
        id
        isStarred
      }
      ... on VirtualEvent {
        id
        isStarred
      }
    }
  }
`;

type Input = {
  eventId: string;
};

type Response = {
  setEventAsPinned: EventDTO;
};
export function useSetEventAsPinned() {
  const [setEvAsPinnedMutation, { loading }] = useMutation<Response, Input>(
    SET_EVENT_AS_PINNED
  );

  async function setEventAsPinned(eventId: string) {
    await setEvAsPinnedMutation({
      variables: {
        eventId,
      },
    });
  }

  return {
    setEventAsPinned,
    loading,
  };
}
