import { useState } from "react";
type ErrorBag = {
  title: string;
  description: string;
  show: boolean;
};
export function useErrorBag() {
  const [errorBag, setErrorBag] = useState<ErrorBag>({
    title: "",
    description: "",
    show: false,
  });

  function openError(title: string, description: string) {
    setErrorBag({
      title,
      description,
      show: true,
    });
  }

  function closeError() {
    setErrorBag({
      title: "",
      description: "",
      show: false,
    });
  }

  return {
    errorBag,
    closeError,
    openError,
  };
}
