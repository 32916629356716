import { GooglePrediction } from "./GooglePrediction";
import Axios from "axios";

export async function placesAutocomplete(
  query: string,
  sessionToken: string,
  language = "it"
): Promise<GooglePrediction[]> {
  const { data } = await Axios.get<{
    predictions: GooglePrediction[];
  }>(
    `${process.env.REACT_APP_JUSTMEET_SERVER_BASE_URL}/geolocation/autocomplete`,
    {
      params: {
        query,
        sessionToken,
        language,
      },
    }
  );
  return data.predictions;
}
