import { BaseFormFieldProps } from "./BaseFormFieldProps";
import { FieldValues, useController } from "react-hook-form";
import { Label } from "../UI/Label";
import { Select, SelectValue } from "../UI/Select";

type Props<TFieldValues> = BaseFormFieldProps<
  TFieldValues,
  {
    label: string;
    options: SelectValue[];
    placeholder?: string;
    msgInCaseOfError?: string;
  }
>;
export function FormSelectField<TFieldValues extends FieldValues>({
  label,
  control,
  fieldName,
  required,
  options,
  placeholder,
  msgInCaseOfError,
}: Props<TFieldValues>) {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name: fieldName,
    control: control,
    rules: { required: required },
  });

  return (
    <div>
      <Label className={"mb-2"}>{label}</Label>
      <Select
        errorString={invalid ? msgInCaseOfError : undefined}
        placeholder={placeholder}
        selected={value}
        setSelected={onChange}
        options={options}
      />
    </div>
  );
}
