import { useState } from "react";

export type SuccessBag = {
  show: boolean;
  title: string;
  description: string;
};

export function useSuccessBag() {
  const [successBag, setSuccessBag] = useState<SuccessBag>({
    title: "",
    description: "",
    show: false,
  });

  function openSuccess(title: string, description: string) {
    setSuccessBag({
      title,
      description,
      show: true,
    });
  }

  function closeSuccess() {
    setSuccessBag({
      title: "",
      description: "",
      show: false,
    });
  }
  return {
    successBag,
    openSuccess,
    closeSuccess,
  };
}
