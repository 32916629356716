import { UserDTO } from "./UserDTO";
import { Navigate } from "react-router-dom";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  user?: UserDTO;
  ifLoggedRedirectTo: string;
}>;
export function OnlyNotLoggedRoute(props: Props) {
  if (props.user) return <Navigate to={props.ifLoggedRedirectTo} />;
  return <>{props.children ?? null}</>;
}
