import { useMenuCtx } from "./useMenuCtx";
import { MenuIcon } from "../UI/Icons/MenuIcon";
import { useAuthCtx } from "../Authentication/useAuthCtx";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ExitIcon } from "../UI/Icons/ExitIcon";
import { PrimaryLoadingIndicator } from "../UI/PrimaryLoadingIndicator";
import { WhiteLoadingIndicator } from "../UI/WhiteLoadingIndicator";

export function JustMeetHeader() {
  const [loggingOut, setLoggingOut] = useState(false);
  const { openMenu } = useMenuCtx();
  const { user, logout } = useAuthCtx();
  async function signOut() {
    try {
      setLoggingOut(true);
      await logout();
    } finally {
      setLoggingOut(false);
    }
  }

  return (
    <header className={"h-14 p-2 flex items-center border-b border-slate-200"}>
      <MenuIcon
        onClick={openMenu}
        className={"h-6 w-6 md:hidden text-gray-600 mr-2"}
      />
      <div className={"ml-auto"}>
        <Menu>
          <Menu.Button>
            <p
              className={
                "text-gray-600 text-sm truncate text-ellipsis max-w-[200px] whitespace-nowrap"
              }
            >
              {user?.email ?? user?.name}
            </p>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mr-2 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={signOut}
                    className={[
                      active ? "bg-primary text-white" : "text-gray-800",
                      "group flex w-full items-center rounded-md px-2 py-2 text-sm",
                    ].join(" ")}
                  >
                    <ExitIcon
                      className={[
                        "mr-2 h-5 w-5",
                        active ? "text-white" : "text-primary",
                      ].join(" ")}
                      aria-hidden="true"
                    />
                    <span>Esci</span>
                    {loggingOut ? (
                      <span className={"ml-2"}>
                        {!active ? (
                          <PrimaryLoadingIndicator size={"small"} />
                        ) : (
                          <WhiteLoadingIndicator size={"small"} />
                        )}
                      </span>
                    ) : null}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
}
