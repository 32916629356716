import { PropsWithChildren } from "react";
import { JustMeetMenu } from "./JustMeetMenu";
import { JustMeetHeader } from "./JustMeetHeader";
import { MenuContextProvider } from "../UI/MenuContextProvider";

type Props = PropsWithChildren<{ showMenu?: boolean; loading?: boolean }>;
export function Layout(props: Props) {
  return (
    <MenuContextProvider>
      <div className={"flex"}>
        {props.showMenu ? <JustMeetMenu /> : null}
        <div className={"min-h-screen flex flex-col flex-1"}>
          {props.showMenu ? <JustMeetHeader /> : null}
          {props.loading ? (
            <div>Loading...</div>
          ) : (
            <>{props.children ?? null}</>
          )}
        </div>
      </div>
    </MenuContextProvider>
  );
}
