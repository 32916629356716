import { gql, useQuery } from "@apollo/client";
import { EventDTO } from "../EventDTO";

const GET_ALL_FUTURE_EVENTS = gql`
  query getAllFutureEvents {
    getAllFutureEvents {
      ... on Event {
        id
        date
        endAt
        type
        title
        description
        gallery {
          src
        }
        isPrivate
        addressString
        isStarred
      }
    }
  }
`;

type Response = {
  getAllFutureEvents: EventDTO[];
};
export function useAllFutureEvents() {
  const { data, loading } = useQuery<Response>(GET_ALL_FUTURE_EVENTS);

  return {
    events: data?.getAllFutureEvents ?? [],
    loading,
  };
}
