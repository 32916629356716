import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { getIdTokenAsync } from "../Firebase/getIdTokenAsync";
import { GetMyEventsFieldsPolicy } from "../Event/EditEvent/useMyEvents";

export class ApolloClientFactory {
  public create(): ApolloClient<NormalizedCacheObject> {
    const setAuthorizationLink = setContext(async (_, { headers }) => {
      const token = await getIdTokenAsync();
      return {
        headers: {
          ...headers,
          Authorization: token,
        },
      };
    });

    const uploadLink = createUploadLink({
      uri: `${process.env.REACT_APP_JUSTMEET_SERVER_BASE_URL}/graphql`,
    });

    return new ApolloClient({
      link: setAuthorizationLink.concat(uploadLink),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              ...GetMyEventsFieldsPolicy,
            },
          },
        },
      }),
    });
  }
}
