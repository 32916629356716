import { gql, useMutation } from "@apollo/client";
import { EventType } from "../EventType";
import { JustMeetAddress } from "../../Geocode/JustMeetAddress";
import { Duration } from "../../UI/DurationInput";

const CREATE_EVENT = gql`
  mutation createEvent(
    $type: EventType!
    $title: String!
    $description: String!
    $place: AddressInput!
    $date: DateTime!
    $durationInMinutes: Int
    $minMaxAge: RangeInput!
    $minMaxPeople: RangeInput!
    $gallery: [Upload!]!
  ) {
    createEvent(
      type: $type
      title: $title
      description: $description
      place: $place
      date: $date
      durationInMinutes: $durationInMinutes
      minMaxAge: $minMaxAge
      minMaxPeople: $minMaxPeople
      gallery: $gallery
    ) {
      id
    }
  }
`;

type AddressInput = {
  city: string;
  country: string;
  lat: number;
  lng: number;
  name: string;
  placeId: string;
  postalCode: string;
  province: string;
  region: string;
  street: string;
};

type RangeInput = {
  min: number;
  max: number;
};

type Input = {
  type: EventType;
  title: string;
  description: string;
  place: AddressInput;
  date: Date;
  durationInMinutes: number;
  minMaxAge: RangeInput;
  minMaxPeople: RangeInput;
  gallery: File[];
};

type CreateEventInput = {
  type: EventType;
  title: string;
  description: string;
  address: JustMeetAddress;
  date: Date;
  duration: Duration | undefined;
  minMaxAge: RangeInput;
  minMaxPeople: RangeInput;
  gallery: File[];
};

function durationToDurationInMinutes(duration: Duration | undefined): number {
  if (!duration) return 0;
  return duration.days * 24 * 60 + duration.hours * 60 + duration.minutes;
}

export function useCreateEvent() {
  const [createEventMutation, { loading }] = useMutation<unknown, Input>(
    CREATE_EVENT
  );
  async function createEvent({
    type,
    title,
    description,
    address,
    date,
    duration,
    minMaxAge,
    minMaxPeople,
    gallery,
  }: CreateEventInput) {
    await createEventMutation({
      variables: {
        type,
        title,
        description,
        place: {
          city: address.city,
          country: address.country,
          lat: address.lat,
          lng: address.lng,
          name: address.toString(),
          placeId: address.placeId,
          postalCode: address.cap,
          province: address.province,
          region: address.region,
          street: [address.street, address.streetNumber].join(" "),
        },
        date,
        durationInMinutes: durationToDurationInMinutes(duration),
        minMaxAge,
        minMaxPeople,
        gallery,
      },
    });
  }
  return {
    createEvent,
    loading,
  };
}
