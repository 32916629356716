import { gql, useQuery } from "@apollo/client";

const GET_POSSIBLE_EVENT_TYPES = gql`
  query getPossibleEventTypes {
    getPossibleEventTypes {
      type
      name
    }
  }
`;

type GetPossibleEventTypesResponse = {
  getPossibleEventTypes: PossibleEventType[];
};

export type PossibleEventType = {
  type: string;
  name: string;
};
export function usePossibleEventTypes() {
  const { data, loading } = useQuery<GetPossibleEventTypesResponse>(
    GET_POSSIBLE_EVENT_TYPES
  );

  return {
    types: data?.getPossibleEventTypes ?? [],
    loading,
  };
}
