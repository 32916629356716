export function CardInputWithLabel({
  name,
  value,
  onChange,
}: {
  name: string;
  value: string;
  onChange: (v: string) => void;
}) {
  return (
    <div className={"h-20 flex flex-col bg-slate-400 rounded-md"}>
      <label className={"p-1 text-center text-white font-bold"}>{name}</label>
      <input
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className={"bg-slate-200 rounded-b-md flex-1 text-center p-2"}
      />
    </div>
  );
}
