import React from "react";
import { WhiteLoadingIndicator } from "./WhiteLoadingIndicator";
import { classNames } from "./CssUtils/classNames";

type Props = {
  label: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  id?: string;
  loading?: boolean;
  disabled?: boolean;
};

const PrimarySolidButton = React.forwardRef<HTMLAnchorElement, Props>(
  function PrimarySolidButtonForwarded(props, ref) {
    return (
      <a
        ref={ref}
        id={props.id}
        onClick={(e) => {
          e.preventDefault();
          if (props.disabled) return;
          props.onClick?.(e);
        }}
        className={classNames([
          "rounded-md px-8 py-2 cursor-pointer flex items-center flex justify-center items-center whitespace-nowrap",
          props.disabled
            ? "bg-slate-200 text-slate-600 cursor-not-allowed"
            : "bg-primary text-white hover:bg-dark-primary",
        ])}
      >
        <span className={classNames([props.loading ? "mr-2" : ""])}>
          {props.label}
        </span>
        {props.loading ? <WhiteLoadingIndicator size={"small"} /> : null}
      </a>
    );
  }
);

export { PrimarySolidButton };
